const COMMON_CONTENT_CONFIG = {
  title: 'Exclusive video of a porn star: Hayley Davies',
  recommended: {
    left: [
      {
        imagePath: '/assets/images/ebony/preview5.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview1.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
    ],
    right: [
      {
        imagePath: '/assets/images/ebony/preview5.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview1.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
    ],
    bottom: [
      {
        imagePath: '/assets/images/ebony/preview.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview2.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview3.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview4.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
      {
        imagePath: '/assets/images/ebony/preview6.webp',
        title: 'Hayley Davies',
        description:
          'Hot stepmom has threesome with stepson and Hindi student fills her face with cum stepson and Hindi student cum',
        viewsCount: 2324,
        likesPercent: 100,
      },
    ],
  },
  mainImagePath: '/assets/images/ebony/main.webp',
};

export const DETAILED_PAGE_CONFIGS = {
  ebony: COMMON_CONTENT_CONFIG,
  latina: COMMON_CONTENT_CONFIG,
  creampie: COMMON_CONTENT_CONFIG,
  anal: COMMON_CONTENT_CONFIG,
  asian: COMMON_CONTENT_CONFIG,
  lesbian: COMMON_CONTENT_CONFIG,
  gay: COMMON_CONTENT_CONFIG,
  milf: COMMON_CONTENT_CONFIG,
  blowjob: COMMON_CONTENT_CONFIG,
};
