import {
  AddIcon,
  CommentsIcon,
  FullScreenIcon,
  LikeIcon,
  PlayButtonIcon,
  SettingsIcon,
  ShareIcon,
  ViewsIcon,
  VolumeIcon,
} from '../../assets/icons';
import style from './style.module.css';

const VideoBlock = ({ imagePath }) => {
  return (
    <div class={style.root}>
      <div class={style.videoBlock}>
        <img src={imagePath} class={style.videoImage} alt="video" />
        <img src={PlayButtonIcon} class={style.playButton} width={150} height={92} alt="play" />
        <div class={style.controlsBlock}>
          <div class={style.progressLine}>
            <div class={style.viewedLine} />
            <div class={style.downloadedLine} />
          </div>
          <div class={style.controlsButtons}>
            <img src={VolumeIcon} alt="volume" />
            <img src={SettingsIcon} alt="settings" />
            <img src={FullScreenIcon} alt="fullscreen" />
          </div>
        </div>
      </div>
      <div class={style.socialBlock}>
        <div class={style.statisticsBlock}>
          <div class={style.statisticsItem}>
            <img src={LikeIcon} width={20} alt="like" />
            <p>95%</p>
          </div>
          <div class={style.statisticsItem}>
            <img src={LikeIcon} width={20} alt="dislike" style={{ transform: 'rotate(180deg)' }} />
            <p>0%</p>
          </div>
          <div class={style.statisticsItem}>
            <img src={ViewsIcon} width={20} alt="views" />
            <p>130K</p>
          </div>
          <div class={style.statisticsItem}>
            <img src={CommentsIcon} width={20} alt="comments" />
            <p>100 Comments</p>
          </div>
        </div>
        <div class={style.shareBlock}>
          <div class={style.statisticsItem}>
            <img src={ShareIcon} alt="share" />
            <p>Share</p>
          </div>
          <div class={style.statisticsItem}>
            <img src={AddIcon} alt="add" />
            <p>Add to</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBlock;
