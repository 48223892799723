import style from './style.module.css';

const GirlBanner = () => (
  <div class={style.girlBanner}>
    <img
      src="/assets/images/hotBanner.webp"
      style={{ cursor: 'pointer' }}
      alt="user"
      class={style.desktopBannerImage}
    />
    <img
      src="/assets/images/mobileHotBanner.webp"
      style={{ cursor: 'pointer' }}
      alt="user"
      class={style.mobileBannerImage}
    />
  </div>
);

export default GirlBanner;
